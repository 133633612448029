<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"><widgets-general-home /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetsGeneralHome from '@/components/widgets/WidgetsGeneral/home'

export default {
  name: 'VbAcceuil',
  components: {
    WidgetsGeneralHome,
  },
}
</script>
