<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <div class="mb-xl-0 mb-3" :class="$style.item">
          <span :class="$style.icon">
            <i class="fe fe-align-center" />
          </span>
          <div :class="$style.desc">
            <span :class="$style.title">Publications</span>
            <p><b>{{publications.count}}</b></p>
          </div>
          <div :class="$style.line" class="bg-success" />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="mb-xl-0 mb-3" :class="$style.item">
          <span :class="$style.icon">
            <i class="fe fe-file-plus" />
          </span>
          <div :class="$style.desc">
            <span :class="$style.title">Actualités</span>
            <p><b>{{news.count}}</b></p>
          </div>
          <div :class="$style.line" class="bg-primary" />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="mb-xl-0 mb-3" :class="$style.item">
          <span :class="$style.icon">
            <i class="fe fe-sidebar" />
          </span>
          <div :class="$style.desc">
            <span :class="$style.title">Débats</span>
            <p><b>{{poll.count}}</b></p>
          </div>
          <div :class="$style.line" class="bg-warning" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-2 mt-5" :class="$style.textDivider">
        <h4 class="font-size-24 font-weight-bold align-center" :class="$style.textDividerContent">
          Statistiques
        </h4>
      </div>
      <div class="table-responsive text-nowrap">
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="false"
          row-key="key"
        >
        </a-table>
      </div>
      </div>
  </div>
</template>
<script>
/* eslint-disable */ 
import { reactive, ref,  defineComponent, computed } from 'vue';
import ApiClient from "@/services/axios"
const columns = [
  {
    title: 'Cible',
    dataIndex: 'target',
    slots: { customRender: 'target' },
  },
  {
    title: 'vu',
    dataIndex: 'seen',
    slots: { customRender: 'seen' },
  },
  {
    title: 'Téléchargements',
    dataIndex: 'download',
    slots: { customRender: 'download' },
  },
]
export default defineComponent({
  name: 'home',
  setup(){
    let publications = reactive({count:0,seen:0,download:0})
    let news = reactive({count:0,seen:0,download:0})
    const poll = reactive({count:0})
    ApiClient.post("/publications/filter",{
      query:{status:'active'},
      aggregation:[     
        { $project: { download: 1, seen: 1 } }
      ]
    })
    .then((res)=>{
      let count= 0
      let seen= 0
      let download= 0
      res.data.forEach(el => {
        count += 1
        seen = ( ! el.seen  ) ? seen : seen + el.seen
        download = ( ! el.download  ) ? download : download + el.download
      });
      publications.count = count
      publications.seen = seen
      publications.download = download
    })
    .catch()
    ApiClient.post("/actualites/filter",{
      query:{status:'active'},
      aggregation:[     
        { $project: { download: 1, seen: 1 } }
      ]
    })
    .then((res)=>{
      let count= 0
      let seen= 0
      let download= 0
      res.data.forEach(el => {
        count += 1
        seen = ( ! el.seen  ) ? seen : seen + el.seen
        download = ( ! el.download  ) ? download : download + el.download
      });
      news.count = count
      news.seen = seen
      news.download = download
    })
    .catch()       
    let data = computed(()=> [
      {
        key:1,
        target:'Publications',
        seen:publications.seen,
        download:publications.download
      },
      {
        key:2,
        target:'Actualités',
        seen:news.seen,
        download:news.download
      },
    ])                     
    return {
      publications,
      news,
      poll,
      data,
      columns
    }
  }
  
})
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
